<script setup>
import { onMounted, ref } from "vue"
import navMenu from "@/components/app-footer/footer.vue";

// const user = useSupabaseUser()
// const supabase = useSupabaseClient()

const currentGame = ref();

useHead({
  title: "Chirp Shot",
  meta: [
    {
      name: "description",
      content: "Chirp Shot is a app that allows you to keep track of your golf scores and stats."
    },
    {
      name: "keywords",
      content: "golf, scorecard, stats, golf stats, golf scorecard"
    },
  ],
  link: [
    {
      rel: "icon",
      type: "image/png",
      href: "/img/favicon.png"
    }
  ]
})

// onMounted(async () => {
//   // If we do not already have a user, sign in anonymously
//   if(!user.value){
//     const { data, error } = await supabase
//     .auth
//     .signInAnonymously()
//   }
// })
</script>

<template>
  <NuxtLayout>
    <template #header>
      <div class="flex place-content-center">
        <h4 class="text-xl font-semibold tracking-tight my-auto">
          Chirp Shot
        </h4>
        <img src="/img/logo.png" class="object-cover h-16 w-16" />
      </div>
    </template>
    <NuxtPage @create-game="ev => currentGame = ev"></NuxtPage>
    <template #footer>
      <nav-menu :currentGame="currentGame"></nav-menu>
    </template>
  </NuxtLayout>
</template>
