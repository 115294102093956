<template>
  <div>this is error page</div>
</template>

<script setup>
const props = defineProps({
  error: Object,
});

// Print types of variables
console.log(typeof props.error.statusCode, typeof '404');

// Does not work, because number !== string
console.log(props.error.statusCode === '404');

// Does work number === number
console.log(props.error.statusCode === 404);

onMounted(() => {
  if (props.error.statusCode === 404) {
    console.log('Error', props.error);
    // clearError({ redirect: '/' });
  }
});
</script>

<style scoped lang="scss"></style>
