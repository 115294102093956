import { default as game_45_91id_93JJEoyYYYGyMeta } from "/opt/buildhome/repo/pages/game-[id].vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as settings4cDYwTsA31Meta } from "/opt/buildhome/repo/pages/settings.vue?macro=true";
import { default as sign_45upDLoukKzOegMeta } from "/opt/buildhome/repo/pages/sign-up.vue?macro=true";
import { default as sliderkXVH2RJonXMeta } from "/opt/buildhome/repo/pages/slider.vue?macro=true";
import { default as tempShUUterUBZMeta } from "/opt/buildhome/repo/pages/temp.vue?macro=true";
export default [
  {
    name: game_45_91id_93JJEoyYYYGyMeta?.name ?? "game-id",
    path: game_45_91id_93JJEoyYYYGyMeta?.path ?? "/game-:id()",
    meta: game_45_91id_93JJEoyYYYGyMeta || {},
    alias: game_45_91id_93JJEoyYYYGyMeta?.alias || [],
    redirect: game_45_91id_93JJEoyYYYGyMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/game-[id].vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: login4dfr6kRqBoMeta?.name ?? "login",
    path: login4dfr6kRqBoMeta?.path ?? "/login",
    meta: login4dfr6kRqBoMeta || {},
    alias: login4dfr6kRqBoMeta?.alias || [],
    redirect: login4dfr6kRqBoMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: settings4cDYwTsA31Meta?.name ?? "settings",
    path: settings4cDYwTsA31Meta?.path ?? "/settings",
    meta: settings4cDYwTsA31Meta || {},
    alias: settings4cDYwTsA31Meta?.alias || [],
    redirect: settings4cDYwTsA31Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: sign_45upDLoukKzOegMeta?.name ?? "sign-up",
    path: sign_45upDLoukKzOegMeta?.path ?? "/sign-up",
    meta: sign_45upDLoukKzOegMeta || {},
    alias: sign_45upDLoukKzOegMeta?.alias || [],
    redirect: sign_45upDLoukKzOegMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: sliderkXVH2RJonXMeta?.name ?? "slider",
    path: sliderkXVH2RJonXMeta?.path ?? "/slider",
    meta: sliderkXVH2RJonXMeta || {},
    alias: sliderkXVH2RJonXMeta?.alias || [],
    redirect: sliderkXVH2RJonXMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/slider.vue").then(m => m.default || m)
  },
  {
    name: tempShUUterUBZMeta?.name ?? "temp",
    path: tempShUUterUBZMeta?.path ?? "/temp",
    meta: tempShUUterUBZMeta || {},
    alias: tempShUUterUBZMeta?.alias || [],
    redirect: tempShUUterUBZMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/temp.vue").then(m => m.default || m)
  }
]